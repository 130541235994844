<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template #activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        v-on="on"
        readonly
      >
        <template #progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar-month</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="dateValue"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="timeValue"
              v-bind="timePickerProps"
              full-width
              format="24hr"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{
            clearText
          }}</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">{{
            okText
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse, parseISO } from "date-fns";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";

export default {
  name: "wtDatetime",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Date, String, undefined, null],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    onlyDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      dateValue: DEFAULT_DATE,
      timeValue: DEFAULT_TIME,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? format(this.selectedDatetime, this.dateTimeFormat)
        : "";
    },
    selectedDate() {
      if (this.dateValue) {
        const datetimeString = this.dateValue;
        return parse(datetimeString, this.dateFormat, new Date());
      } else {
        return null;
      }
    },
    selectedDatetime() {
      if (this.dateValue && this.timeValue) {
        let datetimeString = this.dateValue + " " + this.timeValue;
        if (this.timeValue.length === 5) {
          datetimeString += ":00";
        }
        return parse(datetimeString, this.defaultDateTimeFormat, new Date());
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.dateValue || this.onlyDate;
    },
  },
  methods: {
    init() {
      if (this.value === undefined) {
        this.dateValue = DEFAULT_DATE;
        this.timeValue = DEFAULT_TIME;
        return;
      }
      if (!this.value) {
        this.dateValue = DEFAULT_DATE;
        this.timeValue = DEFAULT_TIME;
        return;
      }

      let initDateTime;
      if (this.value instanceof Date) {
        initDateTime = this.value;
      } else if (
        typeof this.value === "string" ||
        this.value instanceof String
      ) {
        // see https://stackoverflow.com/a/9436948
        if (this.value.indexOf("T") > 0) {
          initDateTime = parseISO(this.value);
        } else {
          initDateTime = parse(this.value, this.dateTimeFormat, new Date());
        }
      }

      this.dateValue = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.timeValue = format(initDateTime, DEFAULT_TIME_FORMAT);
    },
    // mimicISOString(date) {
    //   const d = new Date(date);
    //   let   month = "" + (d.getMonth() + 1);
    //   let   day = "" + d.getDate();
    //   const year = d.getFullYear();

    //   if (month.length < 2) month = "0" + month;
    //   if (day.length < 2) day = "0" + day;

    //   return [year, month, day].join("-") + "T00:00:00.000Z";
    // },
    okHandler() {
      this.resetPicker();
      if (this.onlyDate) {
        //this.$emit("input", this.mimicISOString(this.selectedDatetime));
        this.$emit("input", this.selectedDatetime);
      } else {
        //this.$emit("input", this.selectedDatetime.toISOString());
        this.$emit("input", this.selectedDatetime);
      }
    },
    clearHandler() {
      this.resetPicker();
      this.dateValue = DEFAULT_DATE;
      this.timeValue = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      if (!this.onlyDate) {
        this.activeTab = 1;
      } else {
        this.okHandler();
      }
    },
  },
  watch: {
    value: function () {
      this.init();
    },
  },
};
</script>
